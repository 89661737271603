<template>
  <section class="img-holder" :class="parent">
    <div class="layout">
      <div class="dislogan animate__animated animate__bounce">
        <h1>Welcome to aknan.</h1>
        <p>We Rent, Buy, Develop</p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ["parent"],
};
</script>

<style scoped>
.img-holder {
  width: 100%;
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.projects.img-holder {
  background-image: url("../assets/projects0.jpg");
}

.home.img-holder {
  background-image: url("../assets/home4.webp");
}
.img-holder .layout {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 120px;
}

@media (max-width: 560px) {
  .img-holder .layout {
    justify-content: center;
    padding-left: 0;
  }
}

.img-holder .layout .dislogan {
  background-color: #ffe4c4bd;
  padding: 50px;
  border-radius: 25px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-align: center;
}

.img-holder .layout .dislogan p {
  font-size: 25px;
}
</style>
